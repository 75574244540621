<template>
  <v-row justify="start">
    <v-dialog v-model="dialogViewImg" persistent max-width="800px">
      <v-card class="radius-card hide_overflow" flat>
        <div class="d-flex justify-end pa-3 pb-0">
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div v-if="dataImg" class="px-3 pb-5 pt-0">
          <div class="d-flex justify-center ">
            <img :src="dataImg.image" height="300px" alt="" />
          </div>
          <p class="ma-0 text-center mt-3">{{ dataImg.text }}</p>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "alertQuiz",
  props: ["dialogViewImg", "dataImg"],

  data() {
    return {
      role: ""
    };
  },
  methods: {
    cancelStore() {
      this.$emit("close");
      this.$emit("refetch");
    }
  }
};
</script>
