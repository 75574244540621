<template>
  <div class="_bg-default d-flex pa-3" style="height: 100vh">
    <div style="width: 20%" v-if="dataUser"></div>
    <v-card flat class="radius-card card_chat" min-height="95vh" width="65%">
      <div class="head d-flex align-center">
        <v-btn dark text @click="$router.go(-1)" fab
          ><v-icon>mdi-reply</v-icon></v-btn
        >
        <div
          class="info_receiver d-flex align-center white--text"
          v-if="role == 4"
        >
          <img
            :src="
              dataUser.photo_profile
                ? `${env}/upload/photo_profile/${dataUser.id}/${dataUser.photo_profile}`
                : dummy
            "
            class="dp mr-3"
            alt=""
          />
          <b> {{ dataUser.nama_lengkap }} </b>
        </div>
        <div
          class="info_receiver d-flex align-center white--text"
          v-if="role == 3"
        >
          <img
            :src="dataUser.photo ? dataUser.photo : dummy"
            class="dp mr-3"
            alt=""
          />
          <b>
            {{ dataUser.nama_lengkap }}
          </b>
        </div>
      </div>
      <!-- OVERLAY STUFF -->
      <div>
        <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
          <!-- //loading component -->
          <v-progress-circular
            indeterminate
            class="mr-2"
            color="purple"
          ></v-progress-circular>
          <b class="purple--text">Loading...</b>
        </v-overlay>
      </div>
      <!-- COMPONENT CHATING -->
      <div class="temp_chat pa-3">
        <div v-for="(chat, n) in allMsg" :key="`chatke-${n}`">
          <div
            :id="chat.id"
            :class="chat.from_id == id ? 'my_temp' : 'your_temp'"
          >
            <div :class="chat.from_id == id ? 'my_half' : 'your_half'">
              <div :class="chat.from_id == id ? 'my_ballon' : 'your_ballon'">
                <div>
                  <div
                    class="ur_reply black--text pre_format d-flex align-center"
                    v-if="chat.reply"
                    @click="scrollTo(chat)"
                  >
                    <p class="ma-0 mr-2" v-snip="4">
                      {{ chat.reply.text }}
                    </p>
                    <div v-if="chat.reply.image">
                      <img
                        :src="chat.reply.image"
                        class="reply_img"
                        alt="rpl"
                      />
                    </div>
                  </div>
                  <div v-if="chat.image">
                    <img
                      :src="chat.image"
                      @click="viewImage(chat)"
                      class="image_in_msj"
                      alt=""
                    />
                  </div>
                  <p class="ma-0 pre_format" v-html="chat.text"></p>
                  <small
                    >{{ $date(chat.createdAt).format("HH:mm") }} |
                    {{ $date(chat.createdAt).format("DD/MM") }}</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- component input text -->
      <div class="temp_type"></div>
    </v-card>

    <div>
      <view-img
        :dialogViewImg="dialogViewImg"
        :dataImg="dataViewImg"
        @close="dialogViewImg = false"
      />
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { mapState } from "vuex";
import ViewImg from "./viewImg.vue";
// import Swal from "sweetalert2";
export default {
  components: { ViewImg },
  name: "chatroom",
  computed: {
    ...mapState({
      id: (state) => state.id,
      role: (state) => state.role,
      env: (state) => state.API_URL,
      dummy: (state) => state.dummy,
    }),
  },
  data() {
    return {
      loading: false, //ini loading, awas aja kalo nanya lagi
      sessionInfo: null, //info sesi
      thread: null, //id thread
      interval: null, //interval check time
      timeLeft: null, // remaining time
      duration: null, //durasi konseling
      msg: "", //value kotak typing...
      typeMsg: "text", //tipe mesej
      allMsg: [], //list all mesej
      sending: false, //when mesej is sending
      dataReply: null, //data pesan yang di pilih ketika reply
      isReply: false, //status apakah dia lagi ngereply
      tempPic: null, //template picture
      preUpload: false, //pra upload
      dataUser: null, //data user
      dialogAlert: false,
      second: null,
      minute: null,
      hour: null,
      dialogViewImg: false,
      dataViewImg: null,
      isEnd: null,
      snackbar: false,
      isAlertConfirmed: false,
      alertMinute: null,
      warningTime: null,
      percentTimer: null,
      haventStarted: true,
      alertMsg: {
        color: "",
        msg: "",
      },
    };
  },

  mounted() {
    this.fetchData();
  },
  methods: {
    viewImage(item) {
      this.dataViewImg = item;
      this.dialogViewImg = true;
    },
    showImg(e) {
      const file = e.target.files[0];
      const fr = new FileReader();
      fr.onload = (f) => {
        this.tempPic = f.target.result;
      };
      fr.readAsDataURL(file);
      this.preUpload = true;
    },
    cancelSendImg() {
      this.tempPic = null;
      this.preUpload = false;
    },
    scrollTo(chat) {
      let id = chat.reply.id;
      let tempChat = document.getElementById(id);
      tempChat.scrollIntoView();
      tempChat.style.background = "#9C27B0";
      setTimeout(() => {
        tempChat.style.background = "none";
      }, 1000);
    },

    // ambil data konseling
    fetchData() {
      let endpoint = null;
      if (this.role == 3) {
        endpoint = "trxPsyByd";
      } else {
        endpoint = "viewTransaction";
      }
      this.loading = true;
      let data = this.$route.params.id;
      this.$store.dispatch(`counseling/${endpoint}`, data).then((data) => {
        let item = data[0];
        console.log(item);
        if (this.role == 4) {
          this.dataUser = item.psycholog;
        } else if (this.role == 3) {
          this.dataUser = item.public;
        }
        let session = item.sessions.find((el) => {
          return el.session == item.session_on;
        });

        this.sessionInfo = session;
        this.thread = session.konsultasi_schedule_id.toString();
        this.duration = session.schedule.duration * 60;
        this.fetchMsg();
        this.loading = false;
      });
    },
    // ngelisten pesan pesan
    fetchMsg() {
      let thread = this.$route.params.sesi;
      let db = firebase.firestore();
      let collection = db
        .collection("messages")
        .doc(thread)
        .collection(thread);
      collection.orderBy("createdAt").onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        this.allMsg = data;
        this.checkEndchat();
        setTimeout(function() {
          let scrollDown = document.querySelector(".temp_chat");
          scrollDown.scrollTop = scrollDown.scrollHeight; //auto scroll bottom of page
        }, 100);
      });
    },
  },
};
</script>

<style>
.pre_format {
  word-break: break-all;
  white-space: pre-wrap;
}
.card_chat {
  position: relative;
}
.head {
  height: 10vh;
  background: #3b053c;
}
.temp_chat {
  height: 75vh;
  background: #f9f2fa;
  overflow-y: scroll;
}
.temp_type {
  position: absolute;
  bottom: 0;
  min-height: 10vh;
  width: 100%;
  background: #3b053c;
}
.typing {
  font-size: small;
  line-height: 1.6 !important;
}
.v-textarea textarea {
  line-height: 1.5 !important;
  margin-top: 15px !important;
}
.my_temp {
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  transition: 0.5s;
  /* margin-bottom: 10px; */
  border-radius: 5px;
}
.my_half {
  display: flex;
  justify-content: flex-end;
  width: 70%;
}
.my_ballon {
  position: relative;
  /* display: flex; */
  background: white;
  padding: 5px;
  border-radius: 10px 10px 0 10px;
  box-shadow: 2px 3px 44px -32px rgba(0, 0, 0, 0.75);
  transition: 2s all !important;
}
.ur_reply {
  padding: 5px;
  border-radius: 8px;
  background: rgb(222, 222, 222);
  cursor: pointer;
}
.your_temp {
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  transition: 0.5s;
  /* margin-bottom: 10px; */
  border-radius: 5px;
}
.your_half {
  display: flex;
  justify-content: flex-start;
  width: 70%;
}

.your_ballon {
  position: relative;
  /* display: flex; */
  background: #3b053c;
  padding: 5px;
  color: #fff;
  border-radius: 0 10px 10px 10px;
  box-shadow: 2px 3px 44px -32px rgba(0, 0, 0, 0.75);
  transition: 2s all !important;
}
.overflows {
  position: absolute;
  top: 10px;
  left: 10px;
}
.overflow_alert {
  position: absolute;
  top: 10px;
}
.temp_jam {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  background: white;
  box-shadow: 3px 13px 26px -11px rgba(59, 5, 60, 1);
}
.progress_abs {
  position: absolute;
  right: 20px;
  top: 22px;
  z-index: 2 !important;
}

.temp_rpl {
  padding: 3px;
  cursor: pointer;
  border-radius: 5px;
  background: grey;
}
.container_rpl {
  width: 100%;
  background: #fff;
}
.point_rpl {
  width: 5%;
}
.rpl_txt {
  width: 90%;
}
.text_area {
  min-height: 10vh;
}
.upload-btn {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn input[type="file"] {
  cursor: pointer;
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 45px;
  height: 45px;
}
.img_place {
  padding: 10px;
  background: #fff;
  border-radius: 8px;
}
#chat-message-typer-textarea {
  max-height: 115px;
  overflow-y: auto;
}
.image_in_msj {
  width: 250px;
  height: 250px;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
}
.prereply_img {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
}
.reply_img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
}
.dp {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.warningTimeOut {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
</style>
